import request from '@/utils/request'

const rent_billApi = {
  rent_bill_list: '/rent_bill/',
  rent_bill_create: '/rent_bill/',
  rent_bill_update: '/rent_bill/',
  rent_bill_delete: '/rent_bill/',
  guarantee_bill_list: '/guarantee_bill/'
}

/**
 * 列表
 */
export function rent_bill_list (parameter) {
  return request({
    url: rent_billApi.rent_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}
/**
 * 添加
 */
export function rent_bill_create (parameter) {
  return request({
    url: rent_billApi.rent_bill_create,
    method: 'post',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 修改
 */
export function rent_bill_update (parameter, rent_bill_id) {
  return request({
    url: rent_billApi.rent_bill_update + rent_bill_id + '/',
    method: 'put',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    data: parameter
  })
}
/**
 * 删除
 */
export function rent_bill_delete (rent_bill_id) {
  return request({
    url: rent_billApi.rent_bill_delete + rent_bill_id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}

/**
 * 列表
 */
export function guarantee_bill_list (parameter) {
  return request({
    url: rent_billApi.guarantee_bill_list,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    },
    params: parameter
  })
}

/**
 * 删除减免
 */
export function guarantee_bill_delete (id) {
  return request({
    url: rent_billApi.guarantee_bill_list + id + '/',
    method: 'delete',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
