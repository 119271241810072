<template>
  <div>
    <div class="table-page-search-wrapper">
      <a-form layout="inline" v-action:query>
        <a-row :gutter="48">
          <a-col :md="8" :sm="24" v-if="false">
            <a-form-item label="时间">
              <a-range-picker @change="time" format="YYYY-MM-DD" style="width: 100%" v-model="queryParam.time">
                <template slot="dateRender" slot-scope="current">
                  <div class="ant-calendar-date" :style="getCurrentStyle(current)">
                    {{ current.date() }}
                  </div>
                </template>
              </a-range-picker>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="使用状态" style="width: 100%">
              <a-select v-model="queryParam.status" placeholder="请选择" default-value="0">
                <a-select-option value="">全部</a-select-option>
                <a-select-option :key="key" :value="key" v-for="(val, key) in this.$Dictionaries.BillStatus">{{ val }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="4" :sm="24">
            <a-form-item label="位置">
              <a-select v-model="queryParam.location" placeholder="请选择" allow-clear>
                <a-select-option
                  v-for="item in this.$Dictionaries.location"
                  :value="Object.keys(item)[0]"
                  :key="Object.values(item)[0]"> {{ Object.values(item)[0] }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :md="1" :sm="24">
            <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="middle"
      rowKey="id"
      :loading="loading"
      :columns="columns"
      :pageNum="pageNum"
      :data="loadData"
    >
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <div slot="money_render" slot-scope="text" style="text-align: right; min-width: 120px; max-width: 140px">
        {{ (text / 100).toFixed(2) | numberFormat }}
      </div>
      <span slot="color" slot-scope="color">
        <a-tag
          style="height: 15px"
          :color="color"
        >
          {{ '    ' }}
        </a-tag>
      </span>
      <span slot="action" slot-scope="text, record">
        <template>
          <a-popconfirm
            v-if="record.status === 1"
            title="确定要支付么？"
            ok-text="确定"
            cancel-text="取消"
            :disabled="paying"
            @confirm="handleUpdate(record)"
          >
            <a-spin style="display: inline-block" :spinning="paying && record.id === paymdl.id">
              <a>支付</a>
            </a-spin>
          </a-popconfirm>
        </template>
      </span>
    </s-table>
  </div>
</template>

<script>
import { STable } from '@/components'
import { guarantee_bill_list } from '@/api/rent_bill'
import { property_bill_payment } from '@/api/property_bill'

export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      paymdl: {},
      pageNum: 1,
      tip: '',
      loading: false,
      paying: false,
      // 创建窗口控制
      visible: false,
      mdl: {},
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: 50,
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '需付款',
          scopedSlots: { customRender: 'money_render' },
          align: 'center',
          dataIndex: 'total_payment'
        },
        {
          title: '实际付款',
          scopedSlots: { customRender: 'money_render' },
          align: 'center',
          dataIndex: 'actual_payment'
        },
        {
          title: '位置',
          dataIndex: 'location',
          ellipsis: true,
          customRender: (text) => this.$Dictionaries.location.find((item) => !!item[text])[text]
        },
        {
          title: '状态',
          dataIndex: 'status',
          customRender: (text) => this.$Dictionaries.BillStatus[text],
          ellipsis: true
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 180,
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        console.log('loadData.parameter', parameter)
        return guarantee_bill_list(Object.assign(parameter, this.queryParam))
          .then(res => {
            console.log(res.data, '我是数据列表@')
            return res.data
          })
      },
      selectedRowKeys: [],
      selectedRows: [],

      // custom table alert & rowSelection
      options: {
        alert: {
          show: true,
          clear: () => {
            this.selectedRowKeys = []
          }
        },
        rowSelection: {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      optionAlertShow: false
    }
  },
  created () {
    this.tableOption()
    console.log(this.$Dictionaries)
    this.setCurrentPage()
  },
  methods: {
    setCurrentPage () {
      const page = sessionStorage.getItem('CurrentPage')
      if (page) {
        console.log(page)
        const current = page.split('#$$#')[0]
        const query = page.split('#$$#')[1]
        const path = page.split('#$$#')[2]
        sessionStorage.removeItem('CurrentPage')
        if (path === this.$route.path) {
          if (!isNaN(Number(current))) {
            this.pageNum = Number(current)
          }
          if (query) {
            this.queryParam = JSON.parse(query)
          }
        }
      }
    },
    onChange (value) {
      console.log(value)
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      this.queryParam.start_date = dateStrings[0]
      this.queryParam.end_date = dateStrings[1]
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    tableOption () {
      if (!this.optionAlertShow) {
        this.options = {
          alert: {
            show: true,
            clear: () => {
              this.selectedRowKeys = []
            }
          },
          rowSelection: {
            selectedRowKeys: this.selectedRowKeys,
            onChange: this.onSelectChange
          }
        }
        this.optionAlertShow = true
      } else {
        this.options = {
          alert: false,
          rowSelection: null
        }
        this.optionAlertShow = false
      }
    },
    handleUpdate (record) {
      console.log(record)
      // this.current_bill_id = record.id
      if (this.paying) {
        this.$message.warning('支付请求提交中,请勿重复提交')
        return
      }
      this.paymdl = record
      this.paying = true
      property_bill_payment({ bill_id: record.id, category: 6 }).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh(true)
        }
      }).finally(() => {
        this.paying = false
        this.paymdl = {}
      })
    },
    contractInfo (record) {
      sessionStorage.setItem('RentCurrentPage', this.$refs.table.getCurrent() + '#$$#' + JSON.stringify(this.queryParam) + '#$$#' + this.$route.path)
      this.$router.push('/rent_contract/index/' + record.rent_contract_id)
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
    }
  }
}
</script>
